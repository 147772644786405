import { FeatureLogger } from "./utils/feature-logger";
import { captureErrorAsync } from "./utils/capture-error-async";

const logger = new FeatureLogger("wrap-install");

function isPromise<T, S>(obj: Promise<T> | S): obj is Promise<T> {
  if (obj == null) {
    return false;
  }

  return Boolean(
    (typeof obj === "object" || typeof obj === "function") &&
      typeof obj["then"] === "function" &&
      typeof obj["catch"] === "function"
  );
}

function handleError(featureName: string, e: Error) {
  logger.error("Error when installing feature", featureName, e);
  captureErrorAsync(e);
}

export function wrapInstall(
  featureName: string,
  install: (...args: unknown[]) => unknown
): typeof install {
  return (...args: unknown[]) => {
    try {
      logger.debug("Installing feature", featureName);

      const ret = install(...args);

      if (isPromise(ret)) {
        ret.catch((e) => handleError(featureName, e));
      }

      return ret;
    } catch (e) {
      handleError(featureName, e);
    }
  };
}
