import { logger } from "./logger";
import { loadScript } from "./../utils/load-script";
import { captureErrorAsync } from "./../utils/capture-error-async";

interface Variables {
  accountId: number;
}

// Will install a script in the format
// <script async src="//XXXXX.tctm.co/t.js"></script>
async function installCtmScript(accountId: number) {
  const src = `https://${accountId}.tctm.co/t.js`;

  if (window["__ctm"]) {
    return;
  }

  try {
    await loadScript(src);
    logger.debug(`Successfully loaded CTM script from ${src}`);
  } catch (e) {
    logger.error(`Could not load CTM script from ${src}`);
    captureErrorAsync(e);
  }
}

export function install(variables: Variables, _ctx: FeatureContext): void {
  installCtmScript(variables.accountId);
}
