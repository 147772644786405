import { compact, defaultsDeep, isEmpty } from "lodash";
import { captureErrorAsync } from "./capture-error-async";
import { omitDeepBy } from "./omit-deep.by";
import { analyticsFullyLoaded } from "./ready";

export function userId(): string | undefined | null {
  try {
    return window.analytics?.user().id();
  } catch (_e) {
    return undefined;
  }
}

export async function generateUserIdFromProps(
  props: Record<string, unknown>
): Promise<string | undefined> {
  const previousId = userId();

  if (previousId) {
    return previousId;
  }

  const parts: string[] = [];

  const pushPart = async (type: string, str: string) => {
    const { default: md5 } = await import(
      /* webpackChunkName: "blueimp-md5"*/ "blueimp-md5"
    );
    parts.push(`${type}:${md5(str)}`);
  };

  if (typeof props["email"] === "string") {
    await pushPart("email", props["email"].toLocaleLowerCase());
  } else if (
    typeof props["phone"] === "string" ||
    typeof props["phoneNumber"] === "string"
  ) {
    const { default: parsePhoneNumber } = await import(
      /* webpackChunkName: "libphonenumber-js"*/ "libphonenumber-js"
    );

    const parsed = parsePhoneNumber(
      (props["phone"] || props["phoneNumber"]) as string,
      "US"
    );

    if (parsed?.isPossible()) {
      await pushPart("phone", parsed.format("E.164"));
    }
  } else {
    return undefined;
  }

  return compact(parts).join("/");
}

function applyContext(
  props: Record<string, unknown> | undefined,
  opts?: SegmentAnalytics.SegmentOpts
) {
  const ctx = defaultsDeep({}, window.analytics._defaultContext, opts);

  if (props && props["application"]) {
    ctx.app = { ...ctx.app, name: props["application"] };

    if (ctx.timestamp == null) {
      ctx.timestamp = new Date().toISOString();
    }
  }

  return isEmpty(ctx) ? undefined : ctx;
}

export async function analyticsIdentify(
  props: Record<string, unknown>,
  opts?: SegmentAnalytics.SegmentOpts,
  cb?: () => void
): Promise<void> {
  const args = compact([
    await generateUserIdFromProps(props),
    props,
    applyContext(props, opts),
    cb,
  ]);

  return window.analytics.identify(...compact(args));
}

export const analyticsTrack = (
  eventName: string,
  props: Record<string, unknown> | undefined,
  opts?: SegmentAnalytics.SegmentOpts,
  cb?: () => void
): void => {
  const args = compact([props, applyContext(props, opts), cb]);

  return window.analytics.track(eventName, ...args);
};

export function setAnalyticsContext<T extends Record<string, unknown>>(
  item: T
): T & { [k: string]: unknown } {
  const ret = defaultsDeep({}, window.analytics._defaultContext, item);
  window.analytics._defaultContext = ret;

  return ret;
}

export function setDefaultTraits(
  item: Partial<typeof window.analytics._defaultTraits>
): Partial<typeof window.analytics._defaultTraits> {
  const ret = defaultsDeep({}, window.analytics._defaultTraits, item);
  window.analytics._defaultTraits = ret;

  return ret;
}

export function defaultTraits(): Partial<
  typeof window.analytics._defaultTraits
> {
  return window.analytics?._defaultTraits || {};
}

function tryVal<T extends () => Record<string, unknown>>(cb: T) {
  try {
    return cb() || {};
  } catch (e) {
    captureErrorAsync(e);
    return {};
  }
}

async function extractFloorlyticsBlobData() {
  try {
    await analyticsFullyLoaded();

    return omitDeepBy(
      {
        // @ts-ignore
        traits: tryVal(() => window.analytics.user().traits()),
        // @ts-ignore
        context: tryVal(() => window.analytics._defaultContext),
        user: tryVal(() => ({
          id: window.analytics.user().id(),
          anonymousId: window.analytics.user().anonymousId(),
        })),
      },
      (v) => v == null
    );
  } catch (e) {
    captureErrorAsync(e);
    return { traits: {}, context: {}, user: {} };
  }
}

/**
 * Serializes floorlytics data to be consumed later
 */
export async function floorlyticsBlob(): Promise<string> {
  const blobData = await extractFloorlyticsBlobData();
  const data = { version: 1, ...blobData };
  const jsonString = JSON.stringify(data);

  return btoa(jsonString);
}
