/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function addListener<
  K extends keyof HTMLElementEventMap,
  T extends HTMLElement
>(
  element: T,
  type: K,
  fn: (this: T, ev: HTMLElementEventMap[K]) => void,
  options?: boolean | AddEventListenerOptions
) {
  if (typeof element.addEventListener === "function") {
    return element.addEventListener(type, fn, options);
  } else if (typeof element["attachEvent"] === "function") {
    // @ts-ignore
    return element.attachEvent(`on${type}`, fn);
  } else {
    throw new Error("Could not add an event listener to element");
  }
}

export function removeListener<
  K extends keyof HTMLElementEventMap,
  T extends HTMLElement
>(
  element: T,
  type: K,
  fn: (this: T, ev: HTMLElementEventMap[K]) => void,
  options?: boolean | AddEventListenerOptions
) {
  if (typeof element.removeEventListener === "function") {
    return element.removeEventListener(type, fn, options);
  } else if (typeof element["detachEvent"] === "function") {
    // @ts-ignore
    return element.detachEvent(`on${type}`, fn);
  } else {
    throw new Error("Could not remove an event listener from element");
  }
}
