import { FeatureLogger } from "./feature-logger";

export function stripCdataCommentsFromString(
  str: string | null
): string | undefined {
  return str?.replace("//<![CDATA[", "")?.replace("//]]>", "");
}

export function parseStructuredDataJSON(
  elem: HTMLScriptElement,
  logger: FeatureLogger
): Record<string, unknown> {
  const schemaJson = stripCdataCommentsFromString(elem.textContent);

  if (schemaJson == null) {
    return {};
  }

  try {
    return JSON.parse(schemaJson);
  } catch (e) {
    logger.error("Could not parse structured data JSON", e);
    return {};
  }
}
