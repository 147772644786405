import { mapValues, omitBy } from "lodash";

// eslint-disable-next-line @typescript-eslint/ban-types
export function omitDeepBy<T extends object>(
  object: T,
  testingFunction: (val: unknown) => boolean
): { [P in keyof T]: Partial<T[P]> } {
  if (typeof object !== "object") {
    return object;
  }

  return mapValues(object, (value) => {
    if (typeof value === "object") {
      // eslint-disable-next-line @typescript-eslint/ban-types
      const omitted = omitBy<{}>(value, testingFunction);
      return omitDeepBy(omitted, testingFunction);
    } else {
      // @ts-ignore
      return omitDeepBy(value, testingFunction);
    }
  });
}
