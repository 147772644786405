const cssStyles = "background: #0080ff; color: white; border-radius: 2px;";
const logPrefixes = ["%c DHQ(%s) ", cssStyles];

export class FeatureLogger {
  constructor(public featureName: string) {}

  warn(message: string, ...args: unknown[]): void {
    this.add("warn", message, ...args);
  }

  info(message: string, ...args: unknown[]): void {
    this.add("info", message, ...args);
  }

  log(message: string, ...args: unknown[]): void {
    this.add("log", message, ...args);
  }

  debug(message: string, ...args: unknown[]): void {
    this.add("debug", message, ...args);
  }

  error(message: string, ...args: unknown[]): void {
    this.add("error", message, ...args);
  }

  add(
    level: "warn" | "log" | "debug" | "error" | "info",
    message: string,
    ...args: unknown[]
  ): void {
    try {
      const compatLog = Function.prototype.bind.call(console[level], console); // ie9...
      compatLog(...logPrefixes, this.featureName, message, ...args);
    } catch (e) {
      // ok
    }
  }
}
