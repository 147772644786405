import { FeatureLogger } from "./feature-logger";
const logger = new FeatureLogger("async-capture-error");

const errorTrackingConf = {
  release: process.env.MOSAIC_RELEASE_NAME || "n/a",
  sentryDsn: process.env.SENTRY_DSN,
  environment: process.env.NODE_ENV || "development",
  ignoreErrors: [/SyntaxError: DOM Exception 12/],
};

// Longer stack traces for sentry
try {
  Error.stackTraceLimit = 50;
} catch (e) {
  logger.warn("Could not set Error.stackTraceLimit");
}

export function captureErrorAsync(err: Error): void {
  if (process.env.NODE_ENV === "test") {
    console.error(err);
    return;
  }

  import(
    /* webpackChunkName: "error-tracking" */ "@adhawk/error-tracking/lib/es/mini"
  )
    .then((errorTracking) => {
      errorTracking.init(errorTrackingConf);
      errorTracking.captureException(err);
    })
    .catch((e) =>
      logger.error("An error occured while trying to track error", e)
    );
}
