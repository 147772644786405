import { logger } from "./logger";
import { parseStructuredDataJSON } from "../utils/structured-data";
import { analyticsReady } from "../utils/ready";
import { setAnalyticsContext, setDefaultTraits } from "../utils/analytics";

declare global {
  interface Window {
    analytics?: SegmentAnalytics.AnalyticsJS & {
      _defaultContext?: SegmentAnalytics.SegmentOpts["context"];
      _defaultTraits?: { retailerName?: string };
    };
  }
}

function failsafeCall(
  func: (...args: unknown[]) => unknown,
  ...args: unknown[]
) {
  try {
    return func(...args);
  } catch (e) {
    logger.error("Could not extract page attribute", e);
  }
}

interface Variables {
  timeout?: number;
  retailerName?: string;
  pageVars?: Record<string, unknown>;
  application?: string;
  skipPageview?: boolean;
}

const ACCEPTED_SCHEMA_TYPES = ["Product"];

function extractPageCategory(doc: HTMLDocument) {
  const schemaElement = doc.querySelector<HTMLScriptElement>(
    'script[type="application/ld+json"]'
  );

  if (schemaElement == null) {
    return;
  }

  const parsedSchema = parseStructuredDataJSON(schemaElement, logger);
  const schemaType = parsedSchema["@type"];

  if (
    typeof schemaType === "string" &&
    ACCEPTED_SCHEMA_TYPES.indexOf(schemaType) >= 0
  ) {
    return parsedSchema["@type"];
  }
}

function extractPageName(doc: HTMLDocument) {
  return doc.querySelector<HTMLMetaElement>("meta[itemprop=name]")?.content;
}

function setContext(variables: Variables, context: FeatureContext) {
  setDefaultTraits({
    retailerName: variables.retailerName,
  });

  setAnalyticsContext({
    groupId: context.universalRetailerId,
    app: {
      name: variables.application || "unknown",
    },
  });
}

export async function install(
  variables: Variables,
  context: FeatureContext
): Promise<void> {
  await analyticsReady();

  setContext(variables, context);

  analytics.identify(
    {
      "retailer-id": context.retailerId,
      "universal-retailer-id": context.universalRetailerId,
    },
    window.analytics._defaultContext
  );

  if (!variables.skipPageview) {
    const pageCategory = failsafeCall(extractPageCategory, document);
    const pageName = failsafeCall(extractPageName, document);

    setAnalyticsContext({ page: { name: pageName, category: pageCategory } });

    const pageAttrs = {
      ...(variables.pageVars || {}),
      name: pageName,
      category: pageCategory,
      application: variables.application,
      retailer: {
        dealerhqId: context.retailerId,
        universalRetailerId: context.universalRetailerId,
        name: variables.retailerName,
      },
    };

    window.analytics.page(pageAttrs, window.analytics._defaultContext);
  }
}
