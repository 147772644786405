import { addListener, removeListener } from "./event-listeners";

/* Asynchromously loads a script */
export function loadScript(
  src: string,
  attributes = {}
): Promise<HTMLScriptElement> {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");

    script.type = "text/javascript";
    script.async = true;
    script.src = src;

    for (const key in attributes) {
      script.setAttribute(key, attributes[key]);
    }

    const onLoad = () => {
      removeListener(script, "load", onLoad);
      resolve(script);
    };

    const onError = (ev: ErrorEvent) => {
      removeListener(script, "error", onError);
      reject(ev.error);
    };

    addListener(script, "load", onLoad);
    addListener(script, "error", onError);

    document.head.appendChild(script);
  });
}
